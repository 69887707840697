export const jobRequirements = [
  'Affordable, scalable and performant. The perfect solution for small apps.',
  'A mid-sized solution for businesses undergoing rapid user growth.',
  "A farm of machines entirely dedicated to your company's storage needs.",
  'A mid-sized solution for businesses undergoing rapid user growth.',
  'Affordable, scalable and performant. The perfect solution for small apps.',
  'A mid-sized solution for businesses undergoing rapid user growth.',
  "A farm of machines entirely dedicated to your company's storage needs.",
  'A mid-sized solution for businesses undergoing rapid user growth.',
];
